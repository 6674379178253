f8 {
  font-size: 8px !important;
}

.f10 {
  font-size: 10px !important;
}


.f12 {
  font-size: 12px !important;
}


.f14 {
  font-size: 14px !important;
}

.f16 {
  font-size: 16px !important;
}

.f18 {
  font-size: 18px !important;
}

.f24 {
  font-size: 24px !important;
}

.f32 {
  font-size: 32px !important;
}

.f36 {
  font-size: 36px !important;
}

.f48 {
  font-size: 48px !important;
}

.f72 {
  font-size: 72px !important;
}