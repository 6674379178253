.pi-logo-image {
  width: 100%;
}

.pi-logo-entire {
  margin: 0px auto 0px auto;
}

.resume-main-content {
    overflow: auto;
    overflow-x: hidden;
}

.left-column {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 800px;
}

.connect-button-container {
    position: relative;
    margin-top: 48px;
}

.cv-text-details {
  padding-top: 24px;
  padding-left: 48px;
  width: 90%;
}

.cv-caption {
    margin-top: 16px;
}

.cv-copyright {
    margin: auto 0px 12px 0px;
    color: #808080 !important;
}

.cv-copyright:hover {
    color: #FFF !important;
}

.connect-buttons {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.connect-buttons .connect-button {
  list-style: none;
  margin: 0 15px;
}

.connect-buttons .connect-button .connect-link {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #171515;
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: 0.5s;
}

.resume-second-col {
    padding: 36px;
}

.connect-buttons .connect-button .connect-link:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #D3E3FC;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.connect-buttons .connect-button .connect-link:hover:before {
  transform: scale(1.2);
  box-shadow: 0 0 15px #00887A;
  filter: blur(3px);
}

.connect-buttons .connect-button .connect-link:hover {
  color: #00887A ;
  box-shadow: 0 0 15px #00887A;
  text-shadow: 0 0 15px #00887A;
}


.mailtolink {
text-decoration: none;
position: relative;
}

.mailtolink:after {    
background: none repeat scroll 0 0 transparent;
bottom: 0;
content: "";
display: block;
height: 2px;
left: 50%;
position: absolute;
background: #00887A ;
transition: width 0.3s ease 0s, left 0.3s ease 0s;
width: 0;
}

.resume-first-col {
    padding: 36px;
    padding-bottom: 0px;
}

.mailtolink:hover:after { 
width: 100%; 
left: 0; 
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.portrait {
  width: 300px;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  filter: blur(20px);
  animation: pixelate 2.5s forwards;
}

@keyframes pixelate {
  0% { filter: blur(20px); }
  100% { filter: blur(0px); }
}

.loading-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 5px solid red;
  border-radius: 50%;
  padding: 20px;
}

.joystick {
  position: relative;
  width: 50px;
  height: 50px;
  background: #3498db;
  border-radius: 50%;
  animation: move 2s infinite;
}

.joystick:before,
.joystick:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  background: #f3f3f3;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.joystick:before {
  width: 25px;
  height: 25px;
  animation: rotate 2s infinite linear;
}

@keyframes move {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-20px); }
  75% { transform: translateX(20px); }
}

@keyframes rotate {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.loading-text {
  color: #3498db;
  font-size: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 5px;
  animation: blinkingText 1.2s infinite;
  text-align: center;
}

@keyframes blinkingText {
  0%{     color: #3498db;    }
  49%{    color: #3498db; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #3498db;    }
}

