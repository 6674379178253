.text-green {
  color: #00887A !important;
}

.text-peach {
  color: #FFCCBC !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-offwhite {
  color: #fffeee !important;
}

.text-black {
  color: #000000 !important;
}

.text-cool-grey {
  color: #D3E3FC !important;
}

.text-blue {
  color: #77A6F7 !important;
}

.text-light-grey {
  color: #C8CFD5 !important;
}

