.background-green {
  background-color: #00887A !important;
}

.background-peach {
  background-color: #FFCCBC !important;
}

.background-white {
  background-color: #FFFFFF !important;
}

.background-black {
  background-color: #000000 !important;
}

.background-cool-grey {
  background-color: #D3E3FC !important;
}

.background-blue {
  background-color: #0a1c3f !important;
}

.background-offwhite {
  background-color: #FFFFFC !important;
}
